import React from "react";
import { formatTextWithLineBreaks } from '../utils/helper';
export const About = (props) => {


  return (
    <div id="about">
      <div className="container">
          <div className="row justify-content-center">
              <div className="col-8">
                  <div className="about-text header-section">
                      <h2>{props.data ? props.data.title : "loading..."}</h2>
                      <p>{props.data ? formatTextWithLineBreaks(props.data.text1) : "loading..."}</p>
                      <p>{props.data ? formatTextWithLineBreaks(props.data.text2) : "loading..."}</p>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
};
