import React from 'react';

export const Header = (props) => {
  return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container-fluid">
              <div className="row">
                <div className="logo">
                  {props.data ? (
                      <>
                        <img src={props.data.logo} alt="Logo" />
                      </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
};
